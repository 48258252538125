<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_moveLocAchieveAdd" />
      <v-form v-model="isInputCheck" ref="form">
        <v-container fluid>
          <!-- １行目 開始 -->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelectedName.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        id="get-supplier"
                        v-model="suppliersSelectedName"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single search-autocomplete"
                        readonly
                        persistent-hint
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelectedName }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    id="get-supplier"
                    v-model="suppliersSelectedName"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single search-autocomplete"
                    readonly
                    persistent-hint
                  >
                  </v-text-field>
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- はい替え日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                id="cal"
                v-model="moveLocDate"
                :label="'はい替え日'"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  :allowed-dates="allowedDate"
                  v-model="moveLocDateCal"
                  @input="dateMenu = false"
                />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- １行目 終了 -->

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--戻るボタン-->
              <div class="btn-search-area" style="float: left">
                <v-btn color="primary" class="api-btn" @click="backPage">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>

              <!-- 登録 -->
              <div class="btn-search-area" style="float: right">
                <v-btn color="primary" id="btn-search" class="post-btn" @click="insert">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
              <!-- * -->
            </div>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-form ref="editList" lazy-validation>
          <v-container fluid>
            <v-data-table
              id="listData"
              fixed-header
              :headers="headerItems"
              :items="inputList"
              disable-filtering
              disable-sort
              disable-pagination
              :hide-default-footer="true"
              height="600px"
            >
              <!-- ヘッダーロット -->
              <template v-slot:[`header.lot`]="{ header }">
                <span>
                  <div>{{ header.text }}</div>
                  <span>
                    {{
                      $t("label.lbl_dueDate") +
                      "／" +
                      $t("label.lbl_lotSubkey1") +
                      "／" +
                      $t("label.lbl_lotSubkey2")
                    }}
                  </span>
                </span>
              </template>
              <!-- 商品コード・品名 -->
              <template v-slot:[`item.productCnCd`]="{ item }">
                <div v-if="item.productName.length > 20">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="max-width: 265px" v-bind="attrs" v-on="on">
                        {{ item.productCnCd }}<br />
                        {{ item.incidental }}<br />
                        {{ item.productName }}
                      </div>
                    </template>
                    <div>
                      {{ item.productCnCd }}<br />
                      {{ item.incidental }}<br />
                      {{ item.productName }}
                    </div>
                  </v-tooltip>
                </div>
                <div v-else>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.incidental }}</div>
                  <div>{{ item.productName }}</div>
                </div>
              </template>
              <!-- ロット/S/N-->
              <template v-slot:[`item.lot`]="{ item }">
                <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-overflow" style="width: 260px" v-bind="attrs" v-on="on">
                        {{ item.lot }}<br />
                        {{ item.dueDate }}
                      </div>
                    </template>
                    <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
                  </v-tooltip>
                </div>
                <div v-else>
                  {{ item.lot }}<br />
                  {{ item.dueDate }}
                </div>
              </template>

              <!-- 移動後倉庫/ロケ -->
              <template v-slot:[`item.postWareLoc`]="{ item, index }">
                <div class="loke">
                  <div v-if="item.postWareLoc">
                    {{ item.postWareLoc }}
                  </div>
                  <div v-else>
                    <div class="search-autocomplete-warehouse first-search-item">
                      <v-text-field
                        dense
                        v-model="item.postWareLoc"
                        class="txt-single search-autocomplete-warehouse"
                        readonly
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <v-btn class="ml-auto" small @click="addLocation(index)" text>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </template>

              <!-- 移動数-->
              <template v-slot:[`item.moveQty`]="{ item, index }">
                <v-text-field
                  class="text-box input-number"
                  outlined
                  dense
                  v-model="item.moveQty"
                  @click="clickPop(item.moveQty)"
                  @change="commaMoveQty(item, index)"
                  clear-icon="mdi-close-circle"
                  clearable
                  :rules="[
                    rules.isNumber,
                    rules.inputRequired,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.isUpperNummber,
                  ]"
                  :ref="'moveQty' + index"
                />
              </template>

              <!-- 削除ボタン-->
              <template v-slot:[`item.delete`]="{ item }">
                <v-btn small @click="deleteItem(item)" text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>

              <!--有償・無償-->
              <template v-slot:[`item.isPaid`]="{ item }">
                <v-select :items="freeCostList" v-model="item.isPaid"></v-select>
              </template>
            </v-data-table>
          </v-container>
        </v-form>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :movLocListFlg="infoDialog.movLocListFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />

      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :okAction="insertOk"
        :redMessage="confirmDialog.redMessage"
        :screenFlag="confirmDialog.screenFlag"
        :changeFlag="confirmDialog.changeFlag"
      />

      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 空きスペースダイアログ -->
      <v-dialog v-model="dialogLocation" :max-width="1300">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
          v-on:close="close"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import LocationDialog from "../../views/common/FreeSpaceLocationList.vue";
import sideMenu from "@/components/Menu";

import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
export default {
  name: appConfig.SCREEN_ID.P_MOV_004,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    LocationDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    pListPopup: false,
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      homePageFlag: false,
      movLocListFlg: false,
      firstPageFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
    },
    // 削除リスト
    deleteList: [],
    // ロケーションダイアログ
    dialogLocation: false,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // init時に一時格納するための変数
    fakeInputList: [],
    // 修正削除画面に渡すid
    inListSid: "",
    // 取引先名
    suppliersSelectedName: "",
    // 有償/無償
    isPaid: "2",
    freeCostList: [],
    // 取引先
    suppliersSelected: "",
    // 移動前倉庫
    preWarehouseSelected: "",
    // 移動後倉庫
    warehouseSelected: "",
    // 倉庫リスト
    warehouseList: [],
    // 移動前ロケ
    preLocSelected: "",
    // 移動後ロケ
    locationSelected: "",
    // 移動前ロケーションリスト
    prelocationList: [],
    // 移動後ロケーションリスト
    locationList: [],
    // 品質区分
    prodStatus: "",
    prodStatusList: [],
    // ロット
    lotNo: "",
    // 処理区分
    processDivList: [],
    processDivision: "",
    // 選択された品番/品名
    ProdNameSelected: "",
    prodNameList: [],
    lot: "",
    serial: "",
    dueDate: "",
    lotSubkey1: "",
    lotSubkey2: "",
    // メニュー
    openMenu: null,
    // はい替え日
    moveLocDate: "",
    moveLocDateCal: "",
    dateMenu: false,
    alertMessage: "",
    // ヘッダ
    headerItems: [
      // 明細No
      {
        text: i18n.tc("label.lbl_No"),
        value: "instNo",
        width: "3%",
        sortable: false,
        align: "left",
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "16%",
        align: "left",
        sortable: false,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "10%",
        align: "left",
      },
      // 移動前倉庫/ロケ
      {
        text: i18n.tc("label.lbl_preWarehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "locations",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // 在庫数
      {
        text: i18n.tc("label.lbl_stockQty"),
        value: "stockQty",
        width: "7%",
        align: "right",
        sortable: false,
      },
      // 移動後倉庫/ロケ
      {
        text: i18n.tc("label.lbl_postWarehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "postWareLoc",
        width: "15%",
        align: "center",
        class: "asta",
      },
      // 移動数
      {
        text: i18n.tc("label.lbl_moveQty"),
        value: "moveQty",
        width: "9%",
        align: "center",
        sortable: false,
        class: "asta",
      },
      // 有償無償
      {
        text: i18n.tc("label.lbl_isPaid"),
        value: "isPaid",
        width: "7%",
        align: "center",
      },
      // 削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "delete",
        width: "3%",
        align: "center",
      },
    ],
    // ロケーション情報
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
      clientSid: "",
      itemStandardSid: "",
      itemCd: "",
      itemNm: "",
    },

    detailDialogData: {},
    inputList: [],
    supplierList: [],
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isRangeCheck: (value) => {
        if (value == "" || value == null) {
          // 値が入っていない場合はチェックなし
          return true;
        } else {
          // 処理なし
        }
        if (0 < Number(commonUtil.zen2han(value).replaceAll(",", ""))) {
          return true;
        } else {
          return i18n.tc("check.chk_inputMore");
        }
      },
      isUpperNummber: (value) => {
        if (value == "" || value == null || value == undefined || value == 0) {
          return true;
        } else {
          if (event.target.tagName == "INPUT") {
            // a = 在庫数のtag
            let a =
              event.target.parentElement.parentElement.parentElement.parentElement.parentElement
                .parentElement;
            let aa = a.children[5].innerText;
            let b = Number(commonUtil.zen2han(value).replaceAll(",", ""));
            let qNum = Number(commonUtil.zen2han(aa).replaceAll(",", ""));
            if (b > qNum) {
              // 値が入っていない場合はチェックなし
              return i18n.tc("check.chk_correlation_moveLoc");
            }
          }
          return true;
        }
      },
    },
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.search();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.search();
    },

    // 初期化
    init() {
      this.beforePage = this.$route.params.name;
      this.suppliersSelected = this.$route.params.supplier;
      this.suppliersSelectedName = this.$route.params.clientName;
      // 取引先名をセットするために呼び出す
      this.getMstCode();

      // 在庫詳細を呼び出して画面描画させるために一旦fakeInputListに値を退避
      this.fakeInputList = this.$route.params.checkList.slice();
      // 登録（検索）画面からの遷移の場合
      if (this.fakeInputList[0].instNo.length !== 10) {
        for (let i = 0; i < this.fakeInputList.length; i++) {
          this.fakeInputList[i].isPaid = "02";
          // postWareLocがないので空でセット
          this.fakeInputList[i].postWareLoc = "";
        }
        this.inputList = this.fakeInputList;
      }

      this.itemCd = this.$route.params.itemCd;
      this.lotNo = this.$route.params.lotNo;
      this.serial = this.$route.params.serial;
      this.dueDate = this.$route.params.dueDate;
      this.lotSubkey1 = this.$route.params.lotSubkey1;
      this.lotSubkey2 = this.$route.params.lotSubkey2;
      this.warehouse = this.$route.params.warehouse;
      this.location = this.$route.params.location;
      this.status = this.$route.params.status;
      this.preWarehouse = this.$route.params.preWarehouse;
      this.preLocation = this.$route.params.preLocation;
      this.processDivision = this.$route.params.processDivision;
      this.moveLocDate = this.$route.params.moveDate;
      this.moveLocDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
    },

    /**
     * ロケーション予約画面処理
     * @param 選択した明細のインデックス
     */
    addLocation(index) {
      this.locInfo = {};
      // 画面項目を子画面に引き渡す。
      // 商品SID
      this.locInfo.itemStandardSid = this.inputList[index].itemSid;
      // はい替え日
      this.locInfo.referenceDate = this.moveDate;
      // 取引先ID
      this.locInfo.clientSid = this.suppliersSelected;
      // ロケーション
      this.locInfo.locationSid = this.inputList[index].locationSid;
      // 画面表示用(倉庫・ロケ)
      this.locInfo.locations = this.inputList[index].locations;
      // 品番
      this.locInfo.itemCd = this.inputList[index].productCnCdShow;
      // 品名
      this.locInfo.itemNm = this.inputList[index].productName;
      // インデックス(選択した明細のインデックス)
      this.locInfo.index = index;
      // 画面表示
      this.dialogLocation = true;
      // 画面ID
      this.locInfo.name = appConfig.SCREEN_ID.P_MOV_004;
    },
    // 移動数カンマ処理
    commaMoveQty(val, index) {
      var comma = commonUtil.zen2han(val.moveQty).replaceAll(",", "");
      if (comma.length > 9) {
        comma = comma.substring(0, 9);
      }
      if (!isNaN(Number(comma))) {
        this.inputList[index].moveQty = commonUtil.formatToCurrency(Number(comma));
      }
    },
    clickPop(val) {
      if (val == undefined) {
        return;
      }
      let myName = event.target;
      let pop = val;
      if (val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    /**
     * 子画面ロケーション選択時処理
     * @param {} data 子画面データ
     */
    saveLocation(data) {
      // inputListから対象のデータを取得する。
      let item = this.inputList[this.locInfo.index];
      // 画面表示用設定（倉庫名/ロケーションNo）
      item.postWareLoc = data.warehouse + "/" + data.location;
      // 移動先ロケーションSID
      item.postLocationSid = data.locationSid;
      // 移動先倉庫SID
      item.postWarehouseSid = data.warehouseSid;
      // ダイアログを閉じる
      this.dialogLocation = false;
    },
    /**
     * 子画面閉じるボタン押下
     */
    close() {
      // ダイアログを閉じる
      this.dialogLocation = false;
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 今日以前の日付を選択できないようにする
    allowedDate(val) {
      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return today <= new Date(val);
    },
    // カレンダー
    preDate() {
      let today = dateTimeHelper.convertJST();
      if (this.moveLocDateCal == dateTimeHelper.dateCalc(today)) {
        return;
      }
      let date = new Date(this.moveLocDateCal);
      this.moveLocDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
    },
    nextDate() {
      let date = new Date(this.moveLocDateCal);
      this.moveLocDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    // 日付入力
    changeDate() {
      if (this.moveLocDate.length == 8) {
        var dt = this.moveLocDate;
        this.moveLocDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      // 日付が今日より小さい場合、今日の日付に設定
      if (this.moveLocDate.replaceAll("/", "") < dateTimeHelper.toStringNowYYYYMMDD()) {
        this.moveLocDate = dateTimeHelper.toStringNowYYYY_MM_DD1();
      }
      if (dateTimeHelper.validDate(this.moveLocDate, "/")) {
        this.moveLocDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(this.moveLocDate)
        );
      } else {
        this.moveLocDateCal = null;
      }
    },

    /**
     * 取引先コードリストボックスセット処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    // 戻るボタン押下時
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;

      // 元画面が一覧の場合
      this.$router.push({
        name: appConfig.MENU_ID.P_MOV_ORDER_ADD,
        params: {
          suppliersSelected: this.suppliersSelected,
          itemCd: this.itemCd,
          itemCdList: this.$route.params.itemCdList,
          lotNo: this.lotNo,
          serial: this.serial,
          dueDate: this.dueDate,
          lotSubkey1: this.lotSubkey1,
          lotSubkey2: this.lotSubkey2,
          warehouse: this.warehouse,
          location: this.location,
          locationList: this.$route.params.locationList,
          status: this.status,
        },
      });
    },

    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      this.deleteList.push(this.inputList);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
    },

    // 登録ボタン
    insert() {
      let t = 0;
      if (this.$refs.form.validate()) {
        if (this.inputList == null || this.inputList.length < 1) {
          this.infoDialog.message = i18n.tc("check.che_nothingOrder");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
          this.loadingCounter = 0;
          return;
        }
        for (let i = 0; i < this.inputList.length; ++i) {
          if (!this.inputList[i].postWareLoc) {
            // 移動数のバリデーションチェック
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.message = i18n.tc("check.chk_warehouse");
            this.infoDialog.firstPageFlag = true;
            return;
          }
          if (this.inputList[i].moveQty == undefined) {
            this.infoDialog.message = i18n.tc("check.chk_inputMoveNumber");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            return;
          }
          if (this.inputList[i].moveQty.length > 3) {
            if (!Number(this.inputList[i].moveQty.replaceAll(",", ""))) {
              this.infoDialog.message = i18n.tc("check.chk_inputNumber");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              return;
            } else if (
              Number(this.inputList[i].moveQty.replaceAll(",", "")) >
              Number(this.inputList[i].stockQty.replaceAll(",", ""))
            ) {
              this.infoDialog.message = i18n.tc("check.chk_correlation_moveLoc");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              return;
            }
          } else {
            if (!Number(this.inputList[i].moveQty)) {
              this.infoDialog.message = i18n.tc("check.chk_inputNumber");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              return;
            } else if (Number(this.inputList[i].moveQty) > Number(this.inputList[i].stockQty)) {
              this.infoDialog.message = i18n.tc("check.chk_correlation_moveLoc");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              return;
            }
          }
        }

        if (t > 0) {
          this.infoDialog.message = i18n.tc("check.chk_correlation_moveLoc");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        } else {
          this.confirmDialog.screenFlag = false;
          this.confirmDialog.changeFlag = true;
          this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
          this.confirmDialog.isOpen = true;
        }
      }
    },

    // 登録確定 (OKの場合)
    insertOk() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MOV_004;

      // はい替え実績登録データ保管List
      let bodyList = [];

      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isPaid == "01") {
          this.inputList[i].freeCostDiv = "01";
        } else {
          this.inputList[i].freeCostDiv = "02";
        }

        let inputMap = {};

        // let today = new Date();
        // today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        let nowDate = dateTimeHelper.convertUTC();

        inputMap.opeDispName = i18n.tc("label.lbl_moveLocAchieveAddPc"); // 操作画面名
        inputMap.yyyymmddHhmmss = nowDate; //日時
        inputMap.procCd = "200";
        inputMap.inOutDiv = "01";
        inputMap.itemCd = this.inputList[i].productCnCdShow; //商品cd
        inputMap.lotNo = this.inputList[i].lotNoShow; // ロットNo
        inputMap.shipScheduleQuantity = Number(this.inputList[i].moveQty.replaceAll(",", "")); // 移動数
        inputMap.fromLocationSid = this.inputList[i].locationSid; //fromロケーションSID
        inputMap.toLocationSid = this.inputList[i].postLocationSid; //toロケーションSID
        // inputMap.locaMoveSid = this.inputList[i].postLocationSid; //はい替え指示SID
        inputMap.qualityDiv = this.inputList[i].statusDiv; //品質区分
        inputMap.clientSid = this.suppliersSelected; //取引先SID
        inputMap.itemStandardSid = this.inputList[i].itemSid; //商品標準SID
        bodyList.push(inputMap);
      }
      body.reqIdv.locationMoveActual = bodyList;
      // console.debug("insertOk() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.BIZ_LOCATIONMOVE_ACHIEVE_WITHOUT_INS,
            body,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // console.debug("insertOk() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.movLocListFlg = true;
              this.infoDialog.firstPageFlag = false;

              resolve(response);
              // エラー時
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              //this.infoDialog.message = messsageUtil.getResMessage(jsonData.resCom);

              //エラーメッセージをpopupのmessageに格納;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.firstPageFlag = true;
            }

            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
            this.infoDialog.title = "結果";
            this.infoDialog.isOpen = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            // 画面遷移ボタン(在庫引当画面、ホーム画面、引当結果画面)
            this.infoDialog.thirdPageFlag = false;
            reject(resoleve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // コードマスタAPI (共通API)
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 有償/無償
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);

      Promise.all([freeCostList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.freeCostList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 品質区分API接続
    getDefinition() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.isMst = "1";
      config.params.codeType = "0102";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            // console.debug("getDefinition() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push({
                  text: row.codeInfoList[0].codeName,
                  value: row.code,
                  name: row.codeInfoList[0].codeName,
                });
              });
              this.prodStatusList = list;
            } else {
              // エラー時
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
    // 処理区分API接続
    getStatusDivision() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.isMst = "1";
      config.params.codeType = "0203";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            // console.debug("getStatusDivision() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "未", value: "00", name: "未" }];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push({
                  text: row.codeInfoList[0].codeName,
                  value: row.code,
                  name: row.codeInfoList[0].codeName,
                });
              });
              this.processDivList = list;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
    // 倉庫取得
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSE, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((resolve) => {
            console.error("getWarehouse() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 移動前ロケを取得するメソッド
    getLocationBefore() {
      // 画面遷移判定
      if (this.$route.params.preLocation) {
        // 登録画面から遷移する場合は、引き継いだ値を設定する。
        this.preWarehouseSelected = this.$route.params.preLocation;
      } else {
        // NOP
      }

      if (this.preWarehouseSelected) {
        // 移動前倉庫が選択されている場合、移動前倉庫のロケを取得し移動前ロケリストに設定する。
        this.prelocationList = this.getLocationMst(this.preWarehouseSelected, "", true);
      } else {
        // 空を選択した場合はリセットする。
        this.prelocationList = [];
      }
    },

    // 移動後ロケを取得するメソッド
    getLocationAfter() {
      if (this.warehouseSelected) {
        // 移動後倉庫が選択されている場合、移動後倉庫のロケを取得しロケリストに設定する。
        this.getLocationMst(
          this.warehouseSelected,
          dateTimeHelper.convertUTC(this.moveLocDate),
          false
        );
      } else {
        // 空を選択した場合はリセットする。
        this.locationList = [];
      }
    },

    /**
     * ロケーション取得処理（ロケーションマスタ）
     *
     * @param warehouseSid:倉庫SID
     * @param referenceDate 基準日は、空を引数に設定すると現在時刻となる。
     * @return ロケーションリスト
     */
    getLocationMst(warehouseSid, referenceDate, beforeFlg) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // "0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得
      config.params.searchCategoryDiv = "0";
      // 処理区分
      config.params.processDiv = "1";

      // 倉庫が選択されていれば倉庫に紐づいたロケーションを取得
      if (this.warehouseSelected) {
        config.params.warehouseSid = warehouseSid;
      }

      if (referenceDate) {
        // 基準日が引数で渡されている場合、設定する。
        config.params.reqComReferenceDate = referenceDate;
      }

      // console.debug("getLocationMst(warehouseSid, referenceDate, beforeFlg) Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
          .then((response) => {
            // console.debug(
            //   "getLocationMst(warehouseSid, referenceDate, beforeFlg) Response",
            //   response
            // );
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: row.locationNo,
                  value: row.locationSid,
                  name: row.locationNo,
                });
              });

              // 移動前か移動後のロケリストに入れるかを判定
              if (beforeFlg) {
                // 移動前の場合、移動前のロケリストに設定する。
                this.prelocationList = list;
              } else {
                // 上記以外の場合は、移動後のロケリストに設定する。
                this.locationList = list;
              }
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((resolve) => {
            console.error(
              "getLocationMst(warehouseSid, referenceDate, beforeFlg) Resolve",
              resolve
            );
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    moveLocDateCal(val) {
      this.moveLocDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single-none ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

.loke {
  display: flex;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
