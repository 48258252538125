<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_moveLocAchieveAddIns" />
      <v-form v-model="isInputCheck" ref="form">
        <v-container fluid>
          <!-- １行目 開始 -->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelectedName.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        id="get-supplier"
                        v-model="suppliersSelectedName"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single search-autocomplete"
                        readonly
                        persistent-hint
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelectedName }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    id="get-supplier"
                    v-model="suppliersSelectedName"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single search-autocomplete"
                    readonly
                    persistent-hint
                  >
                  </v-text-field>
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- はい替え日 -->
            <div class="serch-textbox-locDetail">
              <v-text-field
                readonly
                dense
                v-model="moveLocDate"
                class="txt-single"
                :label="$t('label.lbl_moveLocDate')"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 品番/品名 -->
            <div class="serch-textbox-itemCnCd" style="float: left">
              <div v-if="ProdNameSelected.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        readonly
                        dense
                        v-model="ProdNameSelected"
                        class="txt-single"
                        :label="$t('label.lbl_productCnCd')"
                      />
                    </div>
                  </template>
                  <span>{{ ProdNameSelected }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    readonly
                    dense
                    v-model="ProdNameSelected"
                    class="txt-single"
                    :label="$t('label.lbl_productCnCd')"
                  />
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 付帯品番-->
            <div class="search-autocomplete">
              <v-text-field
                readonly
                dense
                v-model="incidental"
                class="txt-single"
                :label="$t('label.lbl_accessoryProduct')"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!--品質区分-->
            <div class="search-autocomplete" style="float: left">
              <v-text-field
                readonly
                dense
                v-model="prodStatus"
                class="txt-single"
                :label="$t('label.lbl_arrivalStatus')"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!--ロット-->
            <div class="serch-textbox-arrivalNo" style="width: 14rem">
              <v-text-field
                readonly
                dense
                v-model="lotNo"
                :label="$t('label.lbl_lot')"
                class="txt-single"
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- S/N -->
            <div class="serch-textbox-arrivalNo">
              <div v-if="serial.length > 20">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        readonly
                        dense
                        v-model="serial"
                        :label="$t('label.lbl_serial')"
                        class="txt-single"
                      />
                    </div>
                  </template>
                  <span>{{ serial }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-text-field
                  readonly
                  dense
                  v-model="serial"
                  :label="$t('label.lbl_serial')"
                  class="txt-single"
                />
              </div>
            </div>
          </v-row>
          <!-- １行目 終了 -->

          <!-- ２行目 開始 -->
          <v-row>
            <div class="search-row-exeBtn">
              <!-- 移動前倉庫-->
              <div class="search-autocomplete-warehouse first-search-item" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="preWarehouseSelected"
                  :label="$t('label.lbl_preWarehouse')"
                  class="txt-single"
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 移動前ロケ-->
              <div class="search-autocomplete-loc" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="preLocSelected"
                  :label="$t('label.lbl_preLocation')"
                  class="txt-single"
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 移動後倉庫-->
              <div class="search-autocomplete-warehouse" style="float: left">
                <v-text-field
                  readonl
                  dense
                  v-model="warehouseSelected"
                  :label="$t('label.lbl_postWarehouse')"
                  class="txt-single"
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- ロケ-->
              <div class="search-autocomplete-loc" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="locationSelected"
                  :items="locationList"
                  :label="$t('label.lbl_location')"
                  class="txt-single"
                />
              </div>
              <div class="item-spacer" style="float: left">&nbsp;</div>

              <!-- 処理区分-->
              <div class="search-autocomplete-processType" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="processDivision"
                  :label="$t('label.lbl_processingdivision')"
                  class="txt-single"
                />
              </div>
              <div class="item-spacer" style="float: left">&nbsp;</div>
              <!-- 有償無償-->
              <div class="search-autocomplete-processType" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="isPaid"
                  :label="$t('label.lbl_isPaid')"
                  class="txt-single"
                />
              </div>
              <div class="item-spacer" style="float: left">&nbsp;</div>
              <!-- 期限日 -->
              <div class="serch-textbox-dueDate" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="dueDate"
                  :label="$t('label.lbl_dueDate')"
                  class="txt-single"
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 付属キー１ -->
              <div class="serch-textbox-arrivalNo" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="lotSubkey1"
                  :label="$t('label.lbl_lotSubkey1')"
                  class="txt-single"
                />
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 付属キー２ -->
              <div class="serch-textbox-arrivalNo" style="float: left">
                <v-text-field
                  readonly
                  dense
                  v-model="lotSubkey2"
                  :label="$t('label.lbl_lotSubkey2')"
                  class="txt-single"
                />
              </div>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--戻るボタン-->
              <div class="btn-search-area" style="float: left">
                <v-btn color="primary" class="api-btn" @click="backPage">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>

              <!-- 登録 -->
              <div class="btn-search-area" style="float: right">
                <v-btn color="primary" id="btn-search" class="post-btn" @click="insert">{{
                  $t("btn.btn_insert")
                }}</v-btn>
              </div>
              <!-- * -->
            </div>
          </v-row>
        </v-container>
        <!-- データテーブル -->
        <v-form ref="editList" lazy-validation>
          <v-container fluid>
            <v-data-table
              id="listData"
              fixed-header
              :headers="headerItems"
              :items="inputList"
              disable-filtering
              disable-sort
              disable-pagination
              :hide-default-footer="true"
              height="630px"
            >
              <!-- 実績数 -->
              <template v-slot:[`item.achieveQty`]="{ item, index }">
                <v-text-field
                  outlined
                  dense
                  v-model="item.achieveQty"
                  :rules="[rules.isNumber, rules.isInteger, rules.inputRequired]"
                  class="input-number"
                  clear-icon="mdi-close-circle input-number"
                  clearable
                  @change="commaachieveQty(item, index)"
                ></v-text-field>
              </template>
              <!-- 担当者 -->
              <template v-slot:[`item.PIC`]="{ item }">
                <v-autocomplete
                  dense
                  outlined
                  class="text-box"
                  v-model="item.PIC"
                  :items="userList"
                >
                  <template slot="item" slot-scope="data">
                    <span class="auto-txt-size"> {{ data.item.text }} </span>
                  </template>
                </v-autocomplete>
              </template>
            </v-data-table>
          </v-container>
        </v-form>
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :movLocListFlg="infoDialog.movLocListFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />

      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :okAction="insertOk"
        :redMessage="confirmDialog.redMessage"
        :screenFlag="confirmDialog.screenFlag"
        :changeFlag="confirmDialog.changeFlag"
      />

      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
export default {
  name: "MovLocationAchieveAddIns",
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      homePageFlag: false,
      movLocListFlg: false,
      firstPageFlag: false,
      outsideClickNotCloseFlg: false,
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
    },
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    // 有償/無償
    isPaid: "",
    // 有償/無償 一覧表示用
    qualityDiv: "",
    // 取引先名
    suppliersSelectedName: "",
    // 取引先
    suppliersSelected: "",
    // 移動前倉庫
    preWarehouseSelected: "",
    // 移動後倉庫
    warehouseSelected: "",
    // 倉庫リスト
    warehouseList: [],
    // 移動前ロケ
    preLocSelected: "",
    // 移動後ロケ
    locationSelected: "",
    // 移動前ロケーションリスト
    prelocationList: [],
    // 移動後ロケーションリスト
    locationList: [],
    // 品質区分
    prodStatus: "",
    prodStatusList: [],
    // ロット
    lotNo: "",
    // S/N
    serial: "",
    // 期限日
    dueDate: "",
    // 付属キー１
    lotSubkey1: "",
    // 付属キー２
    lotSubkey2: "",
    // 処理区分
    processDivList: [],
    processDivision: "",
    // 選択された品番/品名
    ProdNameSelected: "",
    prodNameList: [],
    lot: "",
    // 担当者リスト
    userList: [],
    // メニュー
    openMenu: null,
    // ヘッダ
    headerItems: [
      // 明細No
      {
        text: i18n.tc("label.lbl_instNo"),
        value: "instNo",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 担当者
      {
        text: i18n.tc("label.lbl_PIC"),
        value: "PIC",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "7%",
        align: "center",
      },
      // 在庫数
      {
        text: i18n.tc("label.lbl_stockQty"),
        value: "stockQty",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // 移動数
      {
        text: i18n.tc("label.lbl_moveQty"),
        value: "quantity",
        width: "7%",
        align: "center",
        sortable: false,
      },
      // 実績数
      {
        text: i18n.tc("label.lbl_achieveQty"),
        value: "achieveQty",
        width: "7%",
        align: "center",
        class: "asta",
      },
    ],
    detailDialogData: {},
    inputList: [],
    supplierList: [],
    // 必須項目の受け渡し
    locaMoveSid: "",
    userSid: "",
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
    },
  }),
  methods: {
    // 初期化
    init() {
      // 取引先
      this.suppliersSelected = this.$route.params.supplier;
      // 指示No
      this.locaMoveSid = this.$route.params.locaMoveSid;
      this.userSid = this.$route.params.userSid;
      // 取引先名をセットするために呼び出す
      this.getCustomInfo();
      // 有償無償
      if (this.$route.params.isPaid == "01") {
        this.isPaid = i18n.tc("btn.btn_paid");
      } else {
        this.isPaid = i18n.tc("btn.btn_free");
      }
      // 移動前ロケーション一覧を取得
      this.getLocationBefore();
      // this.getMoveLocAchieveList();
      this.getUsers();
      this.inputList = this.$route.params.checkList.slice();
      for (var m = 0; m < this.$route.params.checkList.length; m++) {
        this.inputList[m].instNo = this.$route.params.checkList[m].locaMoveSid; //指示SID
        this.inputList[m].status = this.$route.params.checkList[m].status; //指示SID
        this.inputList[m].quantity = this.$route.params.checkList[m].quantity; //移動数
        this.inputList[m].stockQty = this.$route.params.checkList[m].systemStock; //在庫数
        this.inputList[m].updateDatetime = this.$route.params.checkList[m].updateDatetime; //更新日時
      }

      // 検索条件をセット
      for (let i = 0; i < this.$route.params.checkList.length; i++) {
        let lotNoObj = commonUtil.convertLotNo(this.$route.params.checkList[i].lotShow);
        // 移動後ロケSid
        this.locationSid = this.$route.params.checkList[i].locationSid;
        // ロット
        this.lotNo = lotNoObj.original.lot;
        //S/N
        this.serial = lotNoObj.original.serial;
        //期限日
        this.dueDate = lotNoObj.original.dueDate;
        //付属キー１
        this.lotSubkey1 = lotNoObj.original.lotSubkey1;
        //付属キー２
        this.lotSubkey2 = lotNoObj.original.lotSubkey2;
        // 品質区分
        this.prodStatus = this.$route.params.checkList[i].status;
        this.ProdNameSelected =
          `${this.$route.params.checkList[i].productCnCd}` +
          `（${this.$route.params.checkList[i].productName}）`;
        this.incidental = this.$route.params.checkList[i].incidental;
        // 移動前倉庫
        this.preWarehouseSelected = this.$route.params.checkList[i].warehouseName;
        // 移動後倉庫
        this.warehouseSelected = this.$route.params.checkList[i].postWarehouseName;
        // 移動前ロケ
        this.preLocSelected = this.$route.params.checkList[i].locationNo;
        // 移動後ロケ
        this.locationSelected = this.$route.params.checkList[i].postLocationNo;
        // はい替え日
        this.moveLocDate = this.$route.params.moveDate.substr(0, 10);
        // 処理区分
        this.processDivision = this.$route.params.checkList[i].completeFlg;
      }
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 戻るボタン押下時
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;
      console.log(this.$route.params.prodNameList);
      this.$router.push({
        name: appConfig.MENU_ID.P_MOV_ORDER_LIST,
        params: {
          suppliersSelected: this.$route.params.supplier,
          itemCd: this.$route.params.itemCd,
          lotNo: this.$route.params.lotNo,
          serial: this.$route.params.serial,
          dueDate: this.$route.params.dueDate,
          lotSubkey1: this.$route.params.lotSubkey1,
          lotSubkey2: this.$route.params.lotSubkey2,
          warehouse: this.$route.params.warehouse,
          location: this.$route.params.location,
          status: this.$route.params.status,
          prodNameSelected: this.$route.params.prodNameSelected,
          lotNo1: this.$route.params.lotNo1,
          preWarehouseSelected: this.$route.params.preWarehouseSelected,
          warehouseSelected: this.$route.params.warehouseSelected,
          preLocSelected: this.$route.params.preLocSelected,
          locationSelected: this.$route.params.locationSelected,
          prodStatus: this.$route.params.prodStatus,
          processDivision: this.$route.params.processDivision,
          moveLocDate: this.$route.params.moveLocDate,
          prodNameList: this.$route.params.prodNameList,
          prelocationList: this.$route.params.prelocationList,
          locationList: this.$route.params.locationList,
          isPaid: this.$route.params.searchIsPaid,
          name: "MovLocationAchieveAddIns",
        },
      });
    },

    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },

    // 登録ボタン
    insert() {
      let cnt = 0;

      // 必須項目の入力がされているかかチェック
      if (this.$refs.editList.validate()) {
        for (var m = 0; m < this.inputList.length; m++) {
          if (this.inputList[m].achieveQty) {
            // 実績数カンマ除去
            var achieveQty = Number(
              commonUtil.zen2han(this.inputList[m].achieveQty).replaceAll(",", "")
            );
            // 数値チェック
            if (isNaN(achieveQty)) {
              cnt = m + 1;
            }
            // 整数チェック
            if (!Number.isInteger(achieveQty)) {
              cnt = m + 1;
            }

            // 範囲チェック（0未満）
            if (achieveQty < 0) {
              cnt = m + 1;
            }

            // 棚卸数と在庫数の数値チェック
            if (Number(this.inputList[m].stockQty) < achieveQty) {
              this.infoDialog.message = i18n.tc("check.chk_correlation_achieveCnt");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              return;
            }
          }
        }
      } else {
        // 必須項目入力がない場合、入力エラー
        return;
      }

      // 明細部、入力エラーなし（カウント0）の場合、確認メッセージ表示
      if (cnt == 0) {
        this.confirmDialog.screenFlag = false;
        this.confirmDialog.changeFlag = true;
        this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.confirmDialog.isOpen = true;
      } else {
        for (var j = 0; j < this.inputList.length; j++) {
          // 関係ないレコードの入力チェックエラーを解除
          if (j !== cnt - 1) {
            eval("this.$refs.achieveQty" + j + ".resetValidation()");
          }
        }
      }
    },

    // 登録実行 (ダイアログOK押下の場合)
    insertOk() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_MOV_005;

      // はい替え実績登録データ保管List
      let bodyList = [];

      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      let nowDate = dateTimeHelper.convertUTC(today);

      for (var i = 0; i < this.inputList.length; i++) {
        // 入庫
        bodyList.push({
          opeDispName: i18n.tc("label.lbl_moveLocAchieveAddIns_PC"), // 操作画面名
          yyyymmddHhmmss: nowDate, //日時
          procCd: "200", //工程コード
          inOutDiv: "01", //入出区分
          itemCd: this.inputList[i].productCnCdShow, //商品cd
          lotNo: this.inputList[i].lotShow, // ロットNo
          shipScheduleQuantity: this.inputList[i].achieveQty.replaceAll(",", ""), // 実績数
          locaMoveSid: this.inputList[i].locaMoveSid, //指示SID
          fromLocationSid: this.inputList[i].locationSid, //fromロケーションSID
          toLocationSid: this.inputList[i].postLocationSid, //toロケーションSID
          responsibleUserSid: this.inputList[i].PIC ?? null, //担当者
          qualityDiv: this.inputList[i].qualityDiv, //品質区分
          updateDatetime: this.inputList[i].updateDatetime, //更新日時
        });
        // 出庫
        bodyList.push({
          opeDispName: i18n.tc("label.lbl_moveLocAchieveAddIns_PC"), // 操作画面名
          yyyymmddHhmmss: nowDate, //日時
          procCd: "400",
          inOutDiv: "02",
          itemCd: this.inputList[i].productCnCdShow, //商品cd
          lotNo: this.inputList[i].lotShow, // ロットNo
          shipScheduleQuantity: this.inputList[i].achieveQty.replaceAll(",", ""), // 実績数
          locaMoveSid: this.inputList[i].locaMoveSid, //指示SID
          fromLocationSid: this.inputList[i].locationSid, //fromロケーションSID
          toLocationSid: this.inputList[i].postLocationSid, //toロケーションSID
          responsibleUserSid: this.inputList[i].PIC ?? null, //担当者
          qualityDiv: this.inputList[i].qualityDiv, //品質区分
          updateDatetime: this.inputList[i].updateDatetime, //更新日時
        });
      }
      body.reqIdv.locationMoveActualList = bodyList;
      // console.debug("insertOk() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_LOCATIONMOVE_ACHIEVE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("insertOk() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.movLocListFlg = true;
              this.infoDialog.firstPageFlag = false;

              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 品質区分API接続
    getDefinition() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.isMst = "1";
      config.params.codeType = "0102";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            // console.debug("getDefinition() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push({
                  text: row.codeInfoList[0].codeName,
                  value: row.code,
                  name: row.codeInfoList[0].codeName,
                });
              });
              this.prodStatusList = list;
            } else {
              // エラー時
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
    // 処理区分API接続
    getStatusDivision() {
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.isMst = "1";
      config.params.codeType = "0203";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            // console.debug("getStatusDivision() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "未", value: "00", name: "未" }];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push({
                  text: row.codeInfoList[0].codeName,
                  value: row.code,
                  name: row.codeInfoList[0].codeName,
                });
              });
              this.processDivList = list;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
    // ユーザー情報取得
    getUsers() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // ★必須パラメータ
      config.params.searchCategoryDiv = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getUsers() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                  name: row.userName,
                  userId: row.userId, //ユーザーID
                });
              });
              this.userList = list;

              // 取得したユーザーリストの中から、明細のユーザーSidと一致するものを探す
              for (var i = 0; i < this.inputList.length; i++) {
                for (var ii = 0; ii < this.userList.length; ii++) {
                  if (this.inputList[i].userSid == this.userList[ii].value) {
                    // inputListの担当者（PIC）に一致したユーザーSidを入れる
                    this.inputList[i].PIC = this.userList[ii].value;
                  }
                }
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 取引先取得
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];

          for (let i = 0; i < this.supplierList.length; i++) {
            if (this.suppliersSelected == this.supplierList[i].value) {
              this.suppliersSelectedName = this.supplierList[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 倉庫取得
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSE, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((resolve) => {
            console.error("getWarehouse() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 移動前ロケを取得するメソッド
    getLocationBefore() {
      // 画面遷移判定
      if (this.$route.params.preLocation) {
        // 登録画面から遷移する場合は、引き継いだ値を設定する。
        this.preWarehouseSelected = this.$route.params.preLocation;
      } else {
        // NOP
      }

      if (this.preWarehouseSelected) {
        // 移動前倉庫が選択されている場合、移動前倉庫のロケを取得し移動前ロケリストに設定する。
        this.prelocationList = this.getLocationMst(this.preWarehouseSelected, "", true);
      } else {
        // 空を選択した場合はリセットする。
        this.prelocationList = [];
      }
    },

    // 移動後ロケを取得するメソッド
    getLocationAfter() {
      if (this.warehouseSelected) {
        // 移動後倉庫が選択されている場合、移動後倉庫のロケを取得しロケリストに設定する。
        this.getLocationMst(
          this.warehouseSelected,
          dateTimeHelper.convertUTC(this.moveLocDate),
          false
        );
      } else {
        // 空を選択した場合はリセットする。
        this.locationList = [];
      }
    },

    /**
     * ロケーション取得処理（ロケーションマスタ）
     *
     * @param warehouseSid:倉庫SID
     * @param referenceDate 基準日は、空を引数に設定すると現在時刻となる。
     * @return ロケーションリスト
     */
    getLocationMst(warehouseSid, referenceDate, beforeFlg) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // "0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得
      config.params.searchCategoryDiv = "0";
      // 処理区分
      config.params.processDiv = "1";

      // 倉庫が選択されていれば倉庫に紐づいたロケーションを取得
      if (this.warehouseSelected) {
        config.params.warehouseSid = warehouseSid;
      }

      if (referenceDate) {
        // 基準日が引数で渡されている場合、設定する。
        config.params.reqComReferenceDate = referenceDate;
      }

      // console.debug("getLocationMst(warehouseSid, referenceDate, beforeFlg) Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
          .then((response) => {
            // console.debug(
            //   "getLocationMst(warehouseSid, referenceDate, beforeFlg) Response",
            //   response
            // );
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", value: "" }];
              jsonData.resIdv.locations.forEach((row) => {
                list.push({
                  text: row.locationNo,
                  value: row.locationSid,
                  name: row.locationNo,
                });
              });

              // 移動前か移動後のロケリストに入れるかを判定
              if (beforeFlg) {
                // 移動前の場合、移動前のロケリストに設定する。
                this.prelocationList = list;
              } else {
                // 上記以外の場合は、移動後のロケリストに設定する。
                this.locationList = list;
              }
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((resolve) => {
            console.error(
              "getLocationMst(warehouseSid, referenceDate, beforeFlg) Resolve",
              resolve
            );
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 実績カンマ区切り処理
    commaachieveQty(val, index) {
      var comma = commonUtil.zen2han(val.achieveQty).replaceAll(",", "");
      comma = comma.replace(/[^0-9]/gi, "");
      if (comma > 9) {
        comma = comma.substring(0, 9);
      }
      if (!isNaN(Number(comma))) {
        this.inputList[index].achieveQty = commonUtil.formatToCurrency(Number(comma));
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        //this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
      this.search();
    },
  },
  created() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}
</style>
